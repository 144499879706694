/***********
 Pre-Loader
***********/
 

  .intro {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
  }

  .intro.ended {
    display: none;
  }
  
  .st0 {
    stroke: #fff;
    fill: #4c98d9;
    stroke-dasharray: 6500;
    opacity: 10;
    animation: animateintro 5.5s cubic-bezier(.17,.67,.83,.67);
    animation-play-state: paused;
  }

  @keyframes animateintro {
    0% {
      opacity: 0;
      fill: none;
      stroke-dashoffset: 6500;
    }
    
    5% {
      opacity: 10;
      fill: none;
      stroke-dashoffset: 6500;
    }
    
    95% {
      fill: rgba(255, 255, 255, 0);
    }
    
    100% {
      opacity: 10;
      fill: #4c98d9;
      stroke-dashoffset: 0;
    }
  }


/****************
 Loading Manager
****************/


.loading-bar-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* transition: all 5s; */
  opacity: 1;
  z-index: 10;
}

.loading-bar-container.ended {

  animation-name: disappearing;
  -webkit-animation-name: disappearing;

  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;

   animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@keyframes disappearing {
  0% {
    opacity: 1;
  }

   100% {
    opacity: 0;
    display: none;
    visibility: hidden;
   }
  }

.loading-bar
{
    position: absolute;
    top: 75%;
    width: 100%;
    height: 2px;
    background: #ffffff;
    transform: scaleX(0.3);
    transform-origin: top left;
    transition: transform 0.5s; 
    display: none;
}

.loading-bar.ended
{
    transform: scaleX(-0.6);
    transform-origin: 100% 0;
    transition: transform 0.55s ease-in-out;
    display: none;
}

#loading {
  position: absolute;
  color: white;
  font-size: 1.2rem;
  font-size: "Source Sans Pro", sans-serif;
  top: 80%;
  justify-content: center;
  display: none;
}

