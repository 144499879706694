@media (width: 750px ) and (height: 550px) {
/*********** 
  General 
***********/

*{
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  
  *, *::after, *::before {
  -webkit-user-select: none;
          user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
  }
  
  .bi {
  -webkit-filter: drop-shadow( 9px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
  }
  
  html,
  body
  {
    overflow: hidden;
    font-family: "Source Sans Pro", sans-serif;
  }
  
  
  /************
  WebGL 
  ************/
  
  
  .webgl
  {
    position: fixed;
    top: auto;
    bottom: auto;
    left: -35vh;
    outline: none;
    /* width: 100;
    height: 100%;
    display: block; */
  }
  
  
  /*******
  Extra
  *******/
  
  
  .icon {
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  position: relative;
  background: none;
  border: none;      
  cursor: pointer;
  margin-inline: 1vh;
  }
  
  
  /*********
  Tooltip
  *********/
  
  
  /* Tooltip container */
  .tooltip {
  /* position: relative; */
  display: inline-block;
  
  /* border-bottom: 1px dotted black; If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
  visibility: hidden;
  width: 11.02vh;
  background-color: #2d2d2d;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 25px;
  
  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 100%;
  left: 55%;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext1  {
  visibility: hidden;
  width: 11.02vh;
  background-color: #2d2d2d;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 25px;
  
  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 100%;
  left: 55%;
  font-size: 12px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .tooltip .tooltiptext2  {
  visibility: hidden;
  width: 11.02vh;
  background-color: #2d2d2d;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 25px;
  
  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 100%;
  left: 55%;
  font-size: 12px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
  visibility: visible;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext1 {
  visibility: visible;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext2 {
  visibility: visible;
  }
  
  
  /******
  Menu 
  ******/
  
  .cols {
    float: right;
    width: 30vh;
    margin-right: 2.5vh;
    margin-top: 12vh;
    display: grid;
  }
  
  .rows {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
  .rows1 {
    height: 35vh;
  }
  
  .rows2 {
    height: 25vh;
  }
  
  .rows3 {
    height: 15vh;
  }
  
  #titel {
    text-align: center;
    place-content: center;
    margin-top: 1.91vh;
    font-family: "Source Sans Pro", sans-serif;
    color: #ffffff;
    letter-spacing: 0.36vh;
    text-shadow: 10px 10px 30px black;
    font-size: 2vh;
  }
  
  #kleuren {
    position:relative;
    background: #2687c5;
    display: grid;
  }
  
  #logo {
    position:relative;
    background: #2376ac;
    display: grid;
  }
  
  #thema {
    position:relative;
    background: #1e6492;
    display: grid;
  
  }
  
  
  /***********************
    Buttons - General 
  ***********************/
  
  
  .btn {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
  
  .button {
    position: relative;
  
    color: white;
    
    border: none;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  
  
    cursor:pointer;
    user-select:none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  
  .button:disabled,
  .button[disabled]{
    cursor: not-allowed;
    pointer-events: none;
  
    opacity: 0.5;
  }
  
  
  /***************** 
    INFORMATIE 
  *****************/
  
  /* 
        " K - ...." = Kleuren
        " L - ...." = Logo
        " T - ...." = Thema
  */
  
  /***************** 
    INFORMATIE 
  *****************/
  
  
    /***********************
      Buttons - Select part
    ***********************/
    
  
    .onderdelen {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      text-align: center;
    }
    
    #leftb {
      position: absolute;
      background: none;
      border: none;      
      cursor: pointer;
      display: none;
      margin-left: -20vh;
      margin-top: -3.70vh;
      width: 4.59vh;
      height: 5.75vh;
      filter: drop-shadow( 2px 4px 4px rgba(0, 0, 0, .7));
    }
  
    #rightb {
      position: absolute;
      background: none;
      border: none;
      cursor: pointer;
      margin-right: -20vh;
      margin-top: -3.70vh;
      width: 4.59vh;
      height: 5.75vh;
      filter: drop-shadow( 2px 4px 4px rgba(0, 0, 0, .7));
    }
  
    #parts {
      font-size: 1.75vh;
      font-family: "Source Sans Pro", sans-serif;
      text-shadow: 10px 10px 30px black;
      color: White;
      margin-top: -5vh;
      padding-right: 1vh;
      padding-left: 1vh;
      text-align: center;
      place-content: middle;
    }
  
  
   .circle-icon{
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    position: relative;
    background: none;
    border: none;      
    cursor: pointer;
    margin-inline: 1vh;
  
    fill: white;
    border-radius: 50%;
   }
  
  
  
    /********************
      Buttons - Color 
    ********************/
  
  
  .button-kleur{
    height: 3.5vh;
    width: 3.5vh;
    margin-inline: 1.38vh;
    margin-bottom: -3.21vh;
    bottom: 3.5vh;
  }
  
    /* Rij 1 */
  
  #k-rood {
    background-color: #660300;
  }
  
  #k-oranje {
    background-color: #CB6015;
  }
  
  #k-geel {
    background-color: #E0A526;
  }
  
  #k-beige {
    background-color: #BF9972;
  }
  
    /* Rij 2 */
  
  #k-donkergroen {
    background-color: #0F3325;
  }
  
  #k-lichtgroen {
    background-color: #409140;
  }
  
  #k-pinkish {
    background-color: #D2ABDB;
  }
  
  #k-paars {
    background-color: #4B365F;
  }
  
    /* Rij 2 */
  
  #k-donkerblauw {
    background-color: #002647;
  }
  
  #k-lichtblauw {
    background-color: #00AAED;
  }
  
  #k-lichtgrijs {
    background-color: #78807A;
  }
  
  #k-donkergrijs {
    background-color: #292C33;
  }
  
  
  
  /******************* 
    Buttons - Logo 
  *******************/
  
  
  .button-logo{
    height: 3.5vh;
    width: 3.5vh;
    margin-inline: 15px;
    margin-bottom: 3vh;
    bottom: 1vh;
  }
  
  .button-logo-big {
    height: 4.5vh;
    width: 4.5vh;
    margin-inline: 15px;
    margin-bottom: 4.5vh;
    /* bottom: -0.5vh; */
  }
  
  #l-no {
    background-color: #2d2d2d;
  }
  
  #l-moogue {
    background-color: #2D2D2D;
  }
  
  #l-nyc {
    background-color: #2D2D2D;
  }
  
  #l-zwart {
    background-color: #000000;
  }
  
  #l-wit {
    background-color: #FFFFFF;
  }
  
  #l-grijs {
    background-color: #707070;
  }
  
  
  /********************
    Buttons - Thema 
  *******************/
  
  
  .button-theme-big{
    height: 4.5vh;
    width: 4.5vh;
    margin-inline: 18px;
    margin-bottom: 2.21vh;
    bottom: 0.5vh;
  }
  
  
  #t-moogue {
    background-color: #2D2D2D;
  }
  
  #t-herfst {
    background-color: #2D2D2D;
  }
  
  #t-kikker {
    background-color: #2D2D2D;
  }
  
  #image {
    display: block;
    margin: auto;
    height: 3vh;
    width: 3vh;
  }

  /********************
    DesktopMenu
  *******************/

  
  .menu {
    filter: url("#goo");
    display: inline;
  }
  
  .menu-item,
  .menu-open-button {
    background: #2887c5;
    border-radius: 100%;
    width: 6.26vh;
    height: 6.26vh;
    margin-left: -40px;
    position: absolute;
    top: -1000px;
    margin-top: 11.5vh;
    float: left;
    color: white;
    text-align: center;
    line-height: 80px;
    transform: translate3d(0, 0, 0);
    transition: transform ease-out 200ms;
  }

  .menu-item {
    transform: scale(0.5, 0.5)
  }
  
  .menu-open {
    display: none;
  }
  
  .circle-icon{
    font-size: 22px;
  }

  .hamburger {
    width: 25px;
    height: 3px;
    background: white;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12.5px;
    margin-top: -1.5px;
    transition: transform 200ms;
  }
  
  .hamburger-1 {
    transform: translate3d(0, -8px, 0);
  }
  
  .hamburger-2 {
    transform: translate3d(0, 0, 0);
  }
  
  .hamburger-3 {
    transform: translate3d(0, 8px, 0);
  }
  
  .menu-open:checked + .menu-open-button .hamburger-1 {
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  .menu-open:checked + .menu-open-button .hamburger-2 {
    transform: translate3d(0, 0, 0) scale(0.1, 1);
  }
  .menu-open:checked + .menu-open-button .hamburger-3 {
    transform: translate3d(0, 0, 0) rotate(-45deg);
  }
  
  .menu {
    position: fixed;
    left: 17.5vh;
    margin-left: -150px;
    padding-top: 50px;
    padding-left: 190px;
    width: 380px;
    height: 250px;
    box-sizing: border-box;
    font-size: 20px;
    text-align: left;
    margin-top: 50px;
  }
  
  .menu-item:nth-child(3) {
    transition-duration: 180ms;
  }
  .menu-item:nth-child(4) {
    transition-duration: 180ms;
  }
  
  .menu-open-button {
    z-index: 2;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 400ms;
    transform: scale(1.1, 1.1) translate3d(0, 0, 0);
    cursor: pointer;
  }
  
  .menu-open:checked + .menu-open-button {
    transition-timing-function: linear;
    transition-duration: 200ms;
    transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  }
  
  .menu-open:checked ~ .menu-item {
    transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  }
  
  .menu-open:checked ~ .menu-item:nth-child(3) {
    display: inline;
    transition-duration: 180ms;
    transform: translate3d(90.9466px, 45.47586px, 0);
  }
  .menu-open:checked ~ .menu-item:nth-child(4) {
    display: inline;
    transition-duration: 280ms;
    transform: translate3d(0.08361px, 95.99997px, 0);
  }


      /******************
       Arrow Animations 
    ******************/
    

    .arrow {
        transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s; /* Safari */
    }
    
    .arrow:hover{
        transition: all 0.3s ease;
        -webkit-transform: scale(1.25);
                transform: scale(1.25);
    }
  
    .arrow:after {
      content: "";
      width: 3.67vh;
      height: 3.67vh;
      transition: all 0.3s
    }
    
    .arrow:active:after {
      padding: 0;
      margin: 0;
      opacity: 1;
      transition: 0s
    }
  
    
    .arrow:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top:0;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.5s;
    }
    
    .arrow:active:after {
      opacity: 1;
      transition: 5s;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    
    .arrow:active {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      transition: 0.2s;
    }


/***************************
    Circle-Icon Animations 
***************************/


  .circle-icon {
    transition: all 0.6s ease;
  }

  .circle-icon:hover {
    transition: all 0.3s ease;
    fill: #2d2d2d;
    -o-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }

  .circle-icon:after {
    content: "";
    width: 3.67vh;
    height: 3.67vh;
    transition: all 0.3s
  }
  
  .circle-icon:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }


/******************************
  Buttons - General Animations 
******************************/


.button{
  transition-duration: 0.4s;
  -o-transition-duration: 0.4s; 
  -ms-transition-duration: 0.4s; 
  -moz-transition-duration: 0.4s; 
  -webkit-transition-duration: 0.4s; /* Safari */
}

[data-radio-selected='true'] {
  transition: all 0.3s ease;
  box-shadow: 0 0 2px 2px #fff;
  -o-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
} 

.button:hover{
  transition: all 0.3s ease;
  box-shadow: 0 0 2px 2px #fff;
  -o-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.button:after {
  content: "";
  width: 3.67vh;
  height: 3.67vh;
  transition: all 0.3s
}

.button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}


.button:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top:0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  
}

.button:active:after {
  box-shadow: 0 0 0 0 rgb(44, 41, 41);
  position: absolute;
  border-radius: 5px;
  left: 0;
  top:0;
  opacity: 1;
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.button:active {
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 0.2s;
}



/***************************
Radiobuttons - Animations
***************************/


input[class="iconradio"] {
  width: 20px;
  height: 20px;
  border: 2px solid #747474;
  border-radius: 50%;
  outline: none;
  opacity: 0.6;
}

input[class="iconradio"]:hover:before {
  box-shadow: 0px 0px 0px 12px rgba(80, 80, 200, 0.2);
  border-radius: 50%;
  opacity: 1;
}

input[class="iconradio"]:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;    
  border-radius: 50%;
  transition: 0.3s;
}  


.option-rot{
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}

.option-rot:hover{
  transition: all 0.3s ease;
  box-shadow: 0 0 2px 2px #fff;
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.option-rot:active:after {
  box-shadow: 0 0 0 0 rgb(44, 41, 41);
  position: absolute;
  border-radius: 5px;
  left: 0;
  top:0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


.option-fps{
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}

.option-fps:hover{
  transition: all 0.3s ease;
  box-shadow: 0 0 2px 2px #fff;
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.option-fps:active:after {
  box-shadow: 0 0 0 0 rgb(44, 41, 41);
  position: absolute;
  border-radius: 5px;
  left: 0;
  top:0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.option-shadow{
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}

.option-shadow:hover{
  transition: all 0.3s ease;
  box-shadow: 0 0 2px 2px #fff;
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.option-shadow:active:after {
  box-shadow: 0 0 0 0 rgb(44, 41, 41);
  position: absolute;
  border-radius: 5px;
  left: 0;
  top:0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.option-color{
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s; /* Safari */
}

.option-color:hover{
  transition: all 0.3s ease;
  box-shadow: 0 0 2px 2px #fff;
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

.option-color:active:after {
  box-shadow: 0 0 0 0 rgb(44, 41, 41);
  position: absolute;
  border-radius: 5px;
  left: 0;
  top:0;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


.navigation-bar {
    display: none;
   }

.mobile-menu{
    display: none;
}

.cols {
    float: right;
    width: 30vh;
    margin-right: 2.5vh;
    margin-top: 12vh;
    display: grid;
  }

  .webgl
{
  position: fixed;
  top: auto;
  bottom: auto;
  left: -35vh;
  outline: none;
  /* width: 100;
  height: 100%;
  display: block; */
}
}