

.wrapperCheck {
  display: flex;

  cursor: pointer;
  
  /* height: 10vh; width: 30vh;  */
  user-select:none;
  text-align: center;
  text-decoration: none;

  color: #000;
  font-size: 24px;
}

input[class="iconradio"] {
  display: none;
}

.check {
  display: flex; flex-wrap: wrap;
  justify-content: center;

  cursor: pointer;

  user-select: none;
  text-decoration: none;
  position: absolute;
}
  
#rotationoption {
  top: 35vh;
  position: fixed;
}

#shadowoption {
  top: 42.5vh;
  position: fixed;
}

@media screen and (max-width: 780px) {
  #rotationoption {
    top: 22.5vh;
    position: fixed;
  }
  
  #shadowoption {
    top: 35vh;
    position: fixed;
  }
}