     /* ************** EXTRA BUTTONS ************** */


     /* .menu {
        filter: url("#goo");
        display: inline;
      }
      
      .menu-item,
      .menu-open-button {
        background: #2887c5;
        border-radius: 100%;
        width: 6.26vh;
        height: 6.26vh;
        margin-left: -40px;
        position: absolute;
        top: -1000px;
        margin-top: 11.5vh;
        float: left;
        color: white;
        text-align: center;
        line-height: 80px;
        transform: translate3d(0, 0, 0);
        transition: transform ease-out 200ms;
      }

      .menu-item {
        transform: scale(0.5, 0.5)
      }
      
      .menu-open {
        display: none;
      }
      
      .circle-icon{
        font-size: 22px;
      }

      .hamburger {
        width: 25px;
        height: 3px;
        background: white;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -12.5px;
        margin-top: -1.5px;
        transition: transform 200ms;
      }
      
      .hamburger-1 {
        transform: translate3d(0, -8px, 0);
      }
      
      .hamburger-2 {
        transform: translate3d(0, 0, 0);
      }
      
      .hamburger-3 {
        transform: translate3d(0, 8px, 0);
      }
      
      .menu-open:checked + .menu-open-button .hamburger-1 {
        transform: translate3d(0, 0, 0) rotate(45deg);
      }
      .menu-open:checked + .menu-open-button .hamburger-2 {
        transform: translate3d(0, 0, 0) scale(0.1, 1);
      }
      .menu-open:checked + .menu-open-button .hamburger-3 {
        transform: translate3d(0, 0, 0) rotate(-45deg);
      }
      
      .menu {
        position: fixed;
        left: 17.5vh;
        margin-left: -150px;
        padding-top: 50px;
        padding-left: 190px;
        width: 380px;
        height: 250px;
        box-sizing: border-box;
        font-size: 20px;
        text-align: left;
        margin-top: 50px;
      }
      
      .menu-item:nth-child(3) {
        transition-duration: 180ms;
      }
      .menu-item:nth-child(4) {
        transition-duration: 180ms;
      }
      
      .menu-open-button {
        z-index: 2;
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-duration: 400ms;
        transform: scale(1.1, 1.1) translate3d(0, 0, 0);
        cursor: pointer;
      }
      
      .menu-open:checked + .menu-open-button {
        transition-timing-function: linear;
        transition-duration: 200ms;
        transform: scale(1.1, 1.1) translate3d(0, 0, 0);
      }
      
      .menu-open:checked ~ .menu-item {
        transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
      }
      
      .menu-open:checked ~ .menu-item:nth-child(3) {
        display: inline;
        transition-duration: 180ms;
        transform: translate3d(90.9466px, 45.47586px, 0);
      }
      .menu-open:checked ~ .menu-item:nth-child(4) {
        display: inline;
        transition-duration: 280ms;
        transform: translate3d(0.08361px, 95.99997px, 0);
      } */
    
    
    
      /*************** MENU BUTTONS ***************/
    
         
         /*************** Ipad ***************/
         
         @media screen and (max-width: 768px) {
            /* .menu {
                filter: url("#goo");
                display: none;
              } */
         }
        

         /* ------------- */
