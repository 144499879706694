

.menu {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 46px;
    width: 46px;
  }
  
  .menu-link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
  
  .menu-icon {
    position: absolute;
    width: 20px;
    height: 14px;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 1px;
  }
  
  /* ------------- */
  .menu-line {
    background-color: #fff;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    position: absolute;
    left: 0;
    transition: all 0.25s ease-in-out;
  }
  .menu-line-2 {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .menu-line-3 {
    bottom: 0;
  }
  .menu.open .menu-line-1 {
    transform: translateY(7px) translateY(-50%) rotate(-45deg);
    background-color: #000;
  }
  .menu.open .menu-line-2 {
    opacity: 0;
  }
  .menu.open .menu-line-3 {
    transform: translateY(-7px) translateY(50%) rotate(45deg);
    background-color: #000;
  }
  
  /* ------------- */
  .menu-circle {
    background-color: #acb8c1; opacity: 0.88;
    width: 100%; height: 100%;

    position: absolute;
    transform: scale(1); transition: transform 0.3s ease-in-out;
    z-index: 3; border-radius: 50%;

    display: flex; justify-content: center;

  }

  .menu-circle-opened {
    background-color: #acb8c1; opacity: 0.88;
    width: 100%; height: 100%;

    position: absolute;
    z-index: 4; border-radius: 50%;

    display: flex; justify-content: center;

  }
  .menu.open .menu-circle {
    transform: scale(60); 
    position: fixed;
  }


  
  /* --- Content --- */
  .menu-overlay {
    background-color: #fff;
    color: #000;
    font-family: "Source Sans Pro", sans-serif;

    left: 0; top: 0;
    right: 0; bottom: 0;

    position: absolute; text-align: center;
    transition: opacity 0.2s ease-in-out;
    z-index: 4; opacity: 0;

    visibility: hidden; display: flex;
    flex-direction: column; align-items: center;
    /* justify-content: center; */ 
    /* pointer-events:none; */
  }

  .menu-overlay.open {
    opacity: 1;
    visibility: visible;
  }

  #settings {
    margin-top: 28px;
    font-size: 26px;
    position: fixed;
  }

  .line1{
    width: 100%;  height: 47px;
    top: 25px;
    border-bottom: 1px solid black;
    position: fixed;
    }

  .line2{
    width: 100%;  height: 47px;
    top: 250px;
    border-bottom: 1px solid black;
    position: fixed;
    }
  
  h4{
    font-size: 24px;
    position: fixed;
    pointer-events: none;
  }

 #rot {
  margin-right: 50px;
 }

 #sha {
  margin-right: 105px;
 }

  #bac {
    top: 350px;
  }

  /* --- Reset Button --- */

  .reset {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 46px;
    width: 46px;
  }

  .resetClicked {
    height: 50px; width: 300px;

    background: #2887c5; border-radius: 15px;

    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);    

    display: flex; flex-wrap: wrap;
    align-content: center; justify-content: center;
    
    display: none;
    opacity: 0;
  }

.elementToFadeInAndOut {
    animation: fadeInOut 2s linear forwards;
}

@keyframes fadeInOut {
 0% { opacity:0; }
 50% { opacity:1; } 
 100% { opacity:0; } 
}


  .resetClickedText {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: white;
  }



  /*************** MENU NAV BUTTONS ***************/
  :root {
    --main-color: #2887c5;
    --second-color: #fff;
  }

  .navigation-bar {
    opacity: 0.88;
    background: #acb8c1;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    display: none;      overflow: hidden;
    justify-content: space-evenly;    
    
    bottom: 34.9%; width: 100%; position: fixed;
    font-family: "Source Sans Pro", sans-serif; font-size: 20px;

  }


  .navigation-bar .list-items {
    list-style: none;
    display: flex;
    position: relative;
  }
  .navigation-bar .list-items .pointer {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 4.5rem;
    z-index: 0;
    transition: all 0.2s linear;
    margin-inline: 1.37vh;
  }
  .navigation-bar .list-items .pointer::before,
  .navigation-bar .list-items .pointer::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
  }

  .navigation-bar .list-items .pointer::after {
    bottom: 0;
    border-top: 8px solid var(--main-color);
    border-radius: 30px 30px 0 0;
  }

  .navigation-bar .list-items .item {
    flex: 1 1 0px;
    position: relative;
    z-index: 2;
  }
  .navigation-bar .list-items .item .link {
    display: inline-block; margin-inline: 1.37vh;
    height: 2.5rem;
    width: 4.5rem;
    line-height: 2rem;
    text-align: center;
    color: var(--second-color);
  }
  .navigation-bar .list-items .item.active .link {
    color: var(--main-color);
  }
  .navigation-bar .list-items .item .link i {
    font-size: 1.6rem;
    transition: font-size 0.2s linear;
  }
  .navigation-bar .list-items .item.active .link i {
    font-size: 1.4rem;
  }

 

    /*************** Desktop ***************/

    @media screen and (max-width: 1024px) {
    .navigation-bar {
      display: flex;
      bottom: 30vh;
      }
    }

    /*************** Ipad ***************/
    
    @media screen and (max-width: 768px) {

    .navigation-bar {
      display: flex;
      bottom: 35vh;
      }
    }

   

        
    @media screen and (min-width: 780px) {
      
      .menu {
        top: 12vh;
        left: 17.5vh; 
      }

      .reset {
        top: 12vh;
        left: 10vh;
      }
      

      .menu.open .menu-circle {
        width: 150%;
        border-radius: 0;
        opacity: 0.5;
        background-color: #2d2d2d;
        z-index: 4;
        display: block;
      }

      .menu-circle {
        transform: scale(1); transition: transform 0s ease-in-out;
        position: absolute;
      }

      .menu-circle.true {
        transform: scale(1); transition: transform 0s ease-in-out;
        position: fixed;
      }

      .menu-circle-opened.true {
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
        
      }


      .menu-overlay {
        border-radius: 50px;
    
        left: 25%; top: 20%;
        right: 25%; bottom: 20%;
    
        position: absolute; text-align: center;
        transition: opacity 0s ease-in-out;
        z-index: 4; opacity: 0;
    
        visibility: hidden; display: flex;
        flex-direction: column; align-items: center;
        

        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
      }

      .line1  {
        left: 25%; right: 25%; top: 25vh;
        position: sticky;
      }
      .line2 {
        left: 25%; right: 25%; top: 45vh;
        position: sticky;
      }


      #bac {
        top: 55vh;
        position: fixed;
      }

      }
  
