


/***************
  Radiobuttons
**************/


.wrapper {
    display: flex; flex-wrap: wrap;
    justify-content: center;
    
    height: 20vh; width: 40vh; 
    user-select:none;
    text-decoration: none;

    font-size: 20px;
    color: white;
    position: fixed; top: 425px;

  }
  
    input[class="iconradio"] {
    display: none;
  }
  


  /******************************
    Radiobuttons - Settings Menu
  ******************************/
  

  
  .option-color {
    height: 10vh;
    width: 10vh;
    display: flex;

    cursor: pointer;
    transition: all 0.5s ease;

    border-style: none;
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  
 .option-colorw {
    background-color: #f1f1f1;
  }
  
  .option-colorg {
    background-color: #6a6a6a;
    margin-left: 3vh;
    margin-right: 3vh;
  }
  
 .option-colorr {
    background-color: #000;
  }
  
  input#color-w:checked ~ .option-1c{
    transition: all 0.3s ease;
    box-shadow: 0 0 2px 2px #2887c5;
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  
  input#color-g:checked ~ .option-2c{
    transition: all 0.3s ease;
    box-shadow: 0 0 2px 2px #2887c5;
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  input#color-r:checked ~ .option-3c{
    transition: all 0.3s ease;
    box-shadow: 0 0 2px 2px #2887c5;
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  
  @media screen and (min-width: 780px) {
    .wrapper {
      display: flex; flex-wrap: wrap;
      justify-content: center;
      
      height: 20vh; width: 50vh; 
      user-select:none;
      text-decoration: none;
  
      font-size: 20px;
      position: fixed; top: 63vh;
  
    }
  
    .option-color {
      height: 10vh;
      width: 10vh;
      display: flex;
  
      cursor: pointer;
      transition: all 0.5s ease;
  
      border-style: none;
      border-radius: 15px;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }

    
    .option-colorg {
      margin-left: 15%;
      margin-right: 15%;
    }

  }