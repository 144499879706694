/*********** 
  General 
***********/

*{
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

*, *::after, *::before {
-webkit-user-select: none;
        user-select: none;
-webkit-user-drag: none;
-webkit-app-region: no-drag;
}

.bi {
  -webkit-filter: drop-shadow( 9px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
}

html,
body
{
  overflow: hidden;
  font-family: "Source Sans Pro", sans-serif;
}


/************
WebGL 
************/


.webgl
{
  position: fixed;
  top: auto;
  bottom: auto;
  left: -35vh;
  outline: none;

  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.webgl:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/*******
Extra
*******/


.icon {
/* display: flex; */
flex-wrap: wrap;
justify-content: center;
align-content: center;
position: relative;
background: none;
border: none;      
cursor: pointer;
margin-inline: 1vh;
}


/******
Menu 
******/

.cols {
  float: right;
  width: 30vh;
  margin-right: 2.5vh;
  margin-top: 12vh;
  display: grid;
}

.rows {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.rows1 {
  height: 35vh;
}

.rows2 {
  height: 25vh;
}

.rows3 {
  height: 15vh;
}

#titel {
  text-align: center;
  place-content: center;
  margin-top: 1.91vh;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  letter-spacing: 0.36vh;
  text-shadow: 10px 10px 30px black;
  font-size: 2vh;
}

#kleuren {
  position:relative;
  background: #2687c5;
  display: grid;
}

#logo {
  position:relative;
  background: #2376ac;
  display: grid;
}

#thema {
  position:relative;
  background: #1e6492;
  display: grid;

}


/***********************
  Buttons - General 
***********************/


.btn {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.button {
  position: relative;

  color: white;
  
  border: none;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);


  cursor:pointer;
  user-select:none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.button:disabled,
.button[disabled]{
  cursor: not-allowed;
  pointer-events: none;

  opacity: 0.5;
}


/***************** 
  INFORMATIE 
*****************/

/* 
      " K - ...." = Kleuren
      " L - ...." = Logo
      " T - ...." = Thema
*/

/***************** 
  INFORMATIE 
*****************/


  /***********************
    Buttons - Select part
  ***********************/
  

  .onderdelen {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  
  #leftb {
    position: absolute;
    background: none;
    border: none;      
    cursor: pointer;
    display: none;
    margin-left: -20vh;
    margin-top: -3.70vh;
    width: 4.59vh;
    height: 5.75vh;
    filter: drop-shadow( 2px 4px 4px rgba(0, 0, 0, .7));
  }

  #rightb {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    margin-right: -20vh;
    margin-top: -3.70vh;
    width: 4.59vh;
    height: 5.75vh;
    filter: drop-shadow( 2px 4px 4px rgba(0, 0, 0, .7));
  }

  #parts {
    font-size: 1.75vh;
    font-family: "Source Sans Pro", sans-serif;
    text-shadow: 10px 10px 30px black;
    color: White;
    margin-top: -5vh;
    padding-right: 1vh;
    padding-left: 1vh;
    text-align: center;
    place-content: middle;
  }


 .circle-icon{
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  position: relative;
  background: none;
  border: none;      
  cursor: pointer;
  margin-inline: 1vh;

  fill: white;
  border-radius: 50%;
 }



  /********************
    Buttons - Color 
  ********************/


.button-kleur{
  height: 3.5vh;
  width: 3.5vh;
  margin-inline: 1.38vh;
  margin-bottom: -3.21vh;
  bottom: 3.5vh;
}

  /* Rij 1 */

#k-rood {
  background-color: #660300;
}

#k-oranje {
  background-color: #CB6015;
}

#k-geel {
  background-color: #E0A526;
}

#k-beige {
  background-color: #BF9972;
}

  /* Rij 2 */

#k-donkergroen {
  background-color: #0F3325;
}

#k-lichtgroen {
  background-color: #409140;
}

#k-pinkish {
  background-color: #D2ABDB;
}

#k-paars {
  background-color: #4B365F;
}

  /* Rij 2 */

#k-donkerblauw {
  background-color: #002647;
}

#k-lichtblauw {
  background-color: #00AAED;
}

#k-lichtgrijs {
  background-color: #78807A;
}

#k-donkergrijs {
  background-color: #292C33;
}



/******************* 
  Buttons - Logo 
*******************/


.button-logo{
  height: 3.5vh;
  width: 3.5vh;
  margin-inline: 15px;
  margin-bottom: 3vh;
  bottom: 1vh;
}

.button-logo-big {
  height: 4.5vh;
  width: 4.5vh;
  margin-inline: 15px;
  margin-bottom: 4.5vh;
  /* bottom: -0.5vh; */
}

#l-no {
  background-color: #2d2d2d;
}

#l-moogue {
  background-color: #2D2D2D;
}

#l-nyc {
  background-color: #2D2D2D;
}

#l-zwart {
  background-color: #000000;
}

#l-wit {
  background-color: #FFFFFF;
}

#l-grijs {
  background-color: #707070;
}


/********************
  Buttons - Thema 
*******************/


.button-theme-big{
  height: 4.5vh;
  width: 4.5vh;
  margin-inline: 18px;
  margin-bottom: 2.21vh;
  bottom: 0.5vh;
}


#t-moogue {
  background-color: #2D2D2D;
}

#t-herfst {
  background-color: #2D2D2D;
}

#t-kikker {
  background-color: #2D2D2D;
}

#image {
  display: block;
  margin: auto;
  height: 3vh;
  width: 3vh;
}


 /*******************************************************************
  RESIZING
 *******************************************************************/


 /*******************
  Laptop
 ******************/


 @media screen and (max-width: 1024px) {
  
  .webgl {
    position:absolute; 
    top:0; left:0; right:0; bottom:0; 
    margin:auto;
    
  }
   /* Menu's */
   .cols {
    position:    fixed;
    width:       100%;      /* The fixed width... */
    margin-left: 0;     /* Shift over half the width */
    margin-top:  0; 
    bottom: 0;    
    margin-right: 0;
  }

  .rows1 {
    height: 30vh;
  }

  .rows2 {
    height: 30vh;
  }

  .rows3 {
    height: 30vh;
  }
  

  .button {
    height: 5vh;
    width: 5vh;
    margin-inline: 4vh;
    margin-bottom: -2vh;
    bottom: 0.5vh;
    top: -2.4vh;
  }
  

  .button:active {
    top: -1.5vh;
  }

  .button-logo{
    height: 5.5vh;
    width: 5.5vh;
    top: -2.4vh;
    margin-bottom: 4.5vh;
    /* bottom: 0.5vh; */
  }

  .button-logo-big {
    margin-inline: 50px;
    margin-bottom: 5.21vh;
    top: -2.4vh;
    height: 6.5vh;
    width: 6.5vh;
  }

  .button-theme-big{
    margin-inline: 50px;
    margin-bottom: 15.21vh;
    bottom: 0.5vh;
    top: -2.4vh;
    height: 6.5vh;
    width: 6.5vh;
  }

  #titel {
    font-size: 24px;
  }

  /* Color Menu */
  #kleuren {
    display: grid;
    position: relative;
  }

  #leftb {
    position: fixed;
    margin-top: -2.5vh;
    margin-left: -32.5vh;
  }

  #rightb {
    position: fixed;
    margin-top: -2.5vh;
    margin-right: -32.5vh;
  }

  #parts {
    position: fixed;
    margin-top: -1.5vh;
    font-size: 2.5vh;
    letter-spacing: 0.76vh;
  }

  /* Logo Menu */
  #logo {
    position: relative;
    display: none;
  }

  /* Thema Menu */
  #thema {
    position: relative;
    display: none;
  }
 }


 /*******************
  Ipad
 ******************/


 @media screen and (max-width: 768px) {

  .webgl {
    position:absolute; 
    top:0; left:0; right:0; bottom:0; 
    margin:auto;
    
  }

  /* Menu's */
  .cols {
    position:    fixed;
    width:       100%;     
    margin-left: 0;     
    margin-top:  0; 
    bottom: 0;    
    margin-right: 0;
  }

  .rows1 {
    height: 35vh;
  }

  .rows2 {
    height: 35vh;
  }

  .rows3 {
    height: 35vh;
  }
  

  .button {
    height: 5vh;
    width: 5vh;
    margin-inline: 4vh;
    margin-bottom: -2vh;
    bottom: 0.5vh;
    top: -2.4vh;
  }
  

  .button:active {
    top: -1.5vh;
  }

  .button-logo{
    height: 5.5vh;
    width: 5.5vh;
    top: -2.4vh;
    margin-bottom: 4.5vh;
  }

  .button-logo-big {
    margin-inline: 50px;
    margin-bottom: 5.21vh;
    top: -2.4vh;
    height: 6.5vh;
    width: 6.5vh;
  }

  .button-theme-big{
    margin-inline: 50px;
    margin-bottom: 15.21vh;
    bottom: 0.5vh;
    top: -2.4vh;
    height: 6.5vh;
    width: 6.5vh;
  }

 
  @media screen and (max-width: 668px) {
    .button{
      height: 5.5vh;
      width: 5.5vh;
      margin-inline: 4vh;
      margin-bottom: 1vh;
      bottom: 0.5vh
    }
    
  }

  @media screen and (max-width: 580px) {
    .button{
      height: 5vh;
      width: 5vh;
      margin-inline: 3.75vh;
      margin-bottom: 1.5vh;
      bottom: 0.5vh;
    }

    .button-kleur{
      margin-bottom: -4vh;
    }
  
    .button-logo{
      height: 5.5vh;
      width: 5.5vh;
      top: -2.4vh;
      margin-bottom: 4.9vh;
    }

    .button-logo-big {
      margin-inline: 40px;
      margin-bottom: 5.21vh;
      top: -2.4vh;
      height: 6.5vh;
      width: 6.5vh;
    }
  
    .button-theme-big{
      margin-inline: 40px;
      margin-bottom: 15.21vh;
      bottom: 0.5vh;
      top: -2.4vh;
      height: 6.5vh;
      width: 6.5vh;
    }
   }

  
  @media screen and (max-width: 520px) {
    .button {
      height: 4.8vh;
      width: 4.8vh;
      margin-inline: 3.5vh;
      margin-bottom: 0.5vh;
    }

    .button-kleur {
      margin-bottom: -1vh;
    }

  }

  #titel {
    font-size: 24px;
  }

  /* Color Menu */
  #kleuren {
    display: grid;
    position: relative;
  }

  #leftb {
    position: fixed;
    margin-top: -2.5vh;
    margin-left: -32.5vh;
  }

  #rightb {
    position: fixed;
    margin-top: -2.5vh;
    margin-right: -32.5vh;
  }

  #parts {
    position: fixed;
    margin-top: -1.5vh;
    font-size: 2.5vh;
    letter-spacing: 0.76vh;
  }

  /* Logo Menu */
  #logo {
    position: relative;
    display: none;
  }

  /* Thema Menu */
  #thema {
    position: relative;
    display: none;
  }
 }

 