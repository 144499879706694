/*******************
  Mobile
 ******************/


  @media screen and (max-width: 480px) {

    /* General */
  
    .webgl {
      position:absolute; 
      top:-15vh; left:0; right:0; bottom:0; 
      margin:auto;
    }
  
    /* Menu's */
    .cols {
      position:    fixed;
      width:       100%;      /* The fixed width... */
      margin-left: 0;     /* Shift over half the width */
      margin-top:  0; 
      bottom: 0;    
      margin-right: 0;
    }
  
    .rows1 {
      height: 35vh;
    }
    
    .rows2 {
      height: 35vh;
    }
    
    .rows3 {
      height: 35vh;
    }
    
    /* Buttons */
    .button {
      height: 4.5vh;
      width: 4.5vh;
      margin-inline: 2.5vh;
      margin-bottom: 2.5vh;
      top: -2.4vh;
    }
  
    .button-kleur {
      margin-bottom: -2vh;
    }
    
    .button:active {
      top: -1.5vh;
    }
  
    .button-theme-big{
      margin-inline: 20px;
      margin-bottom: 14.61vh;
      top: -2.4vh;
      height: 6.5vh;
      width: 6.5vh;
    }
  
    .button-logo{
      height: 4.5vh;
      width: 4.5vh;
      top: -2.4vh;
      margin-bottom: 4.9vh;

    }
  
    .button-logo-big {
      margin-inline: 20px;
      margin-bottom: 5.21vh;
      top: -2.4vh;
      height: 6.5vh;
      width: 6.5vh;
    }
  
    @media screen and (max-width: 426px) {
      .button-kleur {
        margin-inline: 2.3vh;
        margin-bottom: 0.5vh;
        bottom: 0.5vh;
        top: -2.4vh;
        margin-bottom: -2vh;
      }}
  
      @media screen and (max-width: 376px) {
  
      
        .button-kleur {
          margin-inline: 2.5vh;
          height: 4vh;
          width: 4vh;
          top: -2.4vh;
          margin-bottom: -2vh;
        }}
  
        .button:active {
          top: -1vh;
        }
        
        /* @media screen and (max-width: 334px) {
  
          .button {
            margin-inline: 2vh;
            height: 3.75vh;
            width: 3.75vh;
          }}
   */
        
    /* Extra Buttons */
  
   
    /* Color Menu */
    #kleuren {
      display: grid;
      position: relative;
      background: rgb(38,135,197);
      background: radial-gradient(circle, rgba(38,135,197,1) 0%, rgba(35,118,172,1) 50%, rgba(30,100,146,1) 100%);
    }
  
    /* Logo Menu */
    #logo {
      position: relative;
      display: none;
      background: rgb(38,135,197);
      background: radial-gradient(circle, rgba(38,135,197,1) 0%, rgba(35,118,172,1) 50%, rgba(30,100,146,1) 100%);
    }
  
    /* Thema Menu */
    #thema {
      position: relative;
      display: none;
      background: rgb(38,135,197);
      background: radial-gradient(circle, rgba(38,135,197,1) 0%, rgba(35,118,172,1) 50%, rgba(30,100,146,1) 100%);
    }

    #leftb {
      position: fixed;
      margin-top: -3.5vh;
      margin-left: -25vh;
    }
  
    #rightb {
      position: fixed;
      margin-top: -3.5vh;
      margin-right: -25vh;
    }
  
    #parts {
      position: fixed;
      margin-top: -2.5vh;
      font-size: 2.4vh;
      letter-spacing: 0.36vh;
    }
  
  }
  



